import { useState, useRef, useEffect } from 'react';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from "@/lib/lyg/string";
import { useDispatch, useSelector } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FolderManagePopup from "@/pages/comp/board/data_folder/popup/manage";
import ViewFileSlidePopup from "@/pages/comp/basic/file/popup/view_file_slide";
import getXcolumnJson from "./xcolumn/list";
import SearchTabArea from "./area/search_tab";
import SearchArea from "./area/search";
import ManageButtonArea from "./area/manage_btn";
import FileBoxListArea from "./area/box_list";
import DownLoadFileOne from "@/pages/comp/basic/file/common/download/down_one";
import DownLoadFileAndFolder from "@/pages/comp/basic/file/common/download/file_and_folder";

function DataFileListArea(props: any) {
  let myProps = {
    ...props
  };
  const user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "s_public_sort": "comp",//공개구분(super,master,teacher,comp)
    "s_mcomp_seq": "",
    "s_subject_name": "",
    "s_title_like": "",
    "s_writer_like": "",
    "s_par_id": "group_board_data_folder",
    "s_par_seq": "zero",
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [folder_list, set_folder_list] = useState<any>([]);
  const [select_folder_seq, set_select_folder_seq] = useState("");
  const [select_par_folder_arr, set_select_par_folder_arr] = useState([]);//depth arr
  const [check_folder_seq_arr, set_check_folder_seq_arr] = useState([]);
  const [check_file_seq_arr, set_check_file_seq_arr] = useState([]);
  const DownLoadFileOneRef=useRef<any>(null);
  const DownLoadFileAndFolderRef=useRef<any>(null);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//write
    is_update: false,
    row_data: {},
    file_info_arr: [],
    select_file_idx: 0,
    title: "수정",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    list({});
    get_folder_list_by_ajax({});
  }, [user]);

  const list = (inOptObj: any) => {
    let form_json_data = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(form_json_data);
    if (strFunc.is_empty(user.comp_group_info["a_seq"])) {
      setInfoArr([]);
      return false;
    }
    if (strFunc.is_empty(form_json_data["s_par_seq"])) {
      // setInfoArr([]);
      // ListComponentRef.current.setInfoArr([]);
      // return false;
      form_json_data["s_par_seq"]="zero";
    }

    form_json_data["s_group_seq"] = user.comp_group_info["a_seq"];

    if (form_json_data["s_public_sort"] == "comp") {
      form_json_data["s_mcomp_seq"] = user["comp_seq"];
      if (strFunc.is_empty(form_json_data["s_mcomp_seq"])) {
        setInfoArr([]);
        return false;
      }
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/file/file/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          //idx_num
          for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
            response.data["data"]["info_arr"][i]["idx_num"] = i + 1;
          }
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const get_folder_list_by_ajax = (inData: any) => {
    let opt_obj = {
      s_public_sort: listOpt.s_public_sort,
      select_folder_seq:select_folder_seq,
      ...inData
    };
    let form_json_data: any = {
      "s_public_sort": opt_obj.s_public_sort,
      "s_par_folder_seq": opt_obj.select_folder_seq,
      "s_depth":"",
      "order_id": "a_order_num, a_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (strFunc.is_empty(user.comp_group_info["a_seq"])) {
      set_folder_list([]);
      return false;
    }
    form_json_data["s_group_seq"] = user.comp_group_info["a_seq"];
    if(strFunc.is_empty(form_json_data["s_par_folder_seq"])){
      form_json_data["s_depth"]="zero";
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/data/board_data_folder/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_folder_list(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const refresh_data=()=>{
    set_select_folder_seq("");
    set_select_par_folder_arr([]);
    set_check_folder_seq_arr([]);
    set_check_file_seq_arr([]);
  };
  const refresh_data_by_ajax = () => {
    refresh_data();
    get_folder_list_by_ajax({});
    list({now_page:1});
  };

  const go_in_folder=(inData:any)=>{
    let opt_obj={
      folder_seq:"",
      ...inData
    };
    let folder_info=null;
    for(let i=0;i<folder_list.length;i++){
      if(folder_list[i]["a_seq"]==opt_obj.folder_seq){
        folder_info=folder_list[i];
      }
    }

    set_select_folder_seq(opt_obj.folder_seq);
    let new_par_folder_arr:any=[...select_par_folder_arr];
    new_par_folder_arr.push(folder_info);
    set_select_par_folder_arr(new_par_folder_arr);

    set_check_folder_seq_arr([]);
    get_folder_list_by_ajax({select_folder_seq:opt_obj.folder_seq});
    list({now_page:1,s_par_seq:opt_obj.folder_seq});
  };
  const go_out_folder=(inData:any)=>{
    let opt_obj={
      folder_seq:"",
      ...inData
    };

    set_select_folder_seq(opt_obj.folder_seq);
    let new_par_folder_arr:any=[];
    if(!strFunc.is_empty(opt_obj.folder_seq)){
      for(let i=0;i<select_par_folder_arr.length;i++){
        let par_folder_info=select_par_folder_arr[i];
        new_par_folder_arr.push(par_folder_info);
        if(par_folder_info["a_seq"]==opt_obj.folder_seq){
          break;
        }
      }
    }
    set_select_par_folder_arr(new_par_folder_arr);

    set_check_folder_seq_arr([]);
    get_folder_list_by_ajax({select_folder_seq:opt_obj.folder_seq});
    list({now_page:1,s_par_seq:opt_obj.folder_seq});
  };
  const go_back_folder = () => {
    if(select_par_folder_arr.length > 0) {
      let new_par_folder_arr:any=[];
      for(let i=0;i<select_par_folder_arr.length-1;i++){
        new_par_folder_arr.push(select_par_folder_arr[i]);
      }
      let select_folder_seq="";
      if(new_par_folder_arr.length>0){
        select_folder_seq=new_par_folder_arr[new_par_folder_arr.length-1]["a_seq"];
      }
      
      set_select_folder_seq(select_folder_seq);
      set_select_par_folder_arr(new_par_folder_arr);
      set_check_folder_seq_arr([]);
      get_folder_list_by_ajax({select_folder_seq:select_folder_seq});
      list({now_page:1,s_par_seq:select_folder_seq});
    }
  };

  const go_download_file=()=>{
    let down_file_arr=[];
    for(let i=0;i<infoArr.length;i++){
      let file_info=infoArr[i];
      let file_pri_val=file_info["a_ymd"]+","+file_info["a_seq"];
      if(strFunc.str_in_array(file_pri_val,check_file_seq_arr)!=-1){
        down_file_arr.push(file_info);
      }
    }
    if(down_file_arr.length==0&&check_folder_seq_arr.length==0){
      alert("선택된 파일이 없습니다.");
      return false;
    }
    if(down_file_arr.length==1&&check_folder_seq_arr.length==0){
      let one_file=down_file_arr[0];
      if(DownLoadFileOneRef.current){
        DownLoadFileOneRef.current.go_download({
          server_url:one_file["a_serverurl"],
          file_pri_val: one_file["a_ymd"]+","+one_file["a_seq"],
        });
      }
    }else{
      if(DownLoadFileAndFolderRef.current){
        DownLoadFileAndFolderRef.current.go_download({
          file_seq_arr:check_file_seq_arr,
          folder_seq_arr:check_folder_seq_arr,
        });
      }
    }
  };

  const go_delete_file_and_folder_by_ajax = (inData: any) => {
    let opt_obj={
      ...inData
    };
    let is_able_del=false;
    if(check_file_seq_arr.length>0){
      is_able_del=true;
    }
    if(check_folder_seq_arr.length>0){
      is_able_del=true;
    }
    if(is_able_del==false){
      alert("선택된 파일이나 폴더가 없습니다.");
      return false;
    }

    if(!window.confirm("선택된 파일이나 폴더를 삭제하시겠습니까?")){
      return false;
    }

    let form_json_data: any = {
      "file_seq_arr": check_file_seq_arr,
      "folder_seq_arr": check_folder_seq_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/data/board_data_folder/delete_file_and_folder', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          refresh_data_by_ajax();
          alert("삭제되었습니다.");
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const open_folder_manage_popup = () => {
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "폴더관리",
      "sort": "folder_manage",
    });
  };

  const open_view_file_slide_popup = (inData:any) => {
    let opt_obj={
      file_info_arr:infoArr,
      select_file_pri_val:"",
      ...inData
    };
    let file_info_arr=opt_obj.file_info_arr;
    let img_file_info_arr:any=[];
    for(let i=0;i<file_info_arr.length;i++){
      if(file_info_arr[i]["is_image"]=="1"){
        img_file_info_arr.push(file_info_arr[i]);
      }
    }
    let select_file_pri_val=opt_obj.select_file_pri_val;
    let select_file_idx=0;
    for(let i=0;i<img_file_info_arr.length;i++){
      let file_pri_val=img_file_info_arr[i]["a_ymd"]+","+img_file_info_arr[i]["a_seq"];
      if(file_pri_val==select_file_pri_val){
        select_file_idx=i;
      }
    }
    setPopupData({
      ...popupData,
      file_info_arr:img_file_info_arr,
      select_file_idx:select_file_idx,
      "isOpen": true,
      "title": "폴더관리",
      "sort": "view_file_slide",
      "width": "100%",
      "height": "100%"
    });
  };

  return (
    <div>
      <SearchTabArea
        listOpt={listOpt}
        list={list}
        set_select_folder_seq={set_select_folder_seq}
        get_folder_list_by_ajax={get_folder_list_by_ajax}
        refresh_data={refresh_data}
      ></SearchTabArea>
      <div className="mt-1">
        <SearchArea
          listOpt={listOpt}
          setListOpt={setListOpt}
          list={list}
        ></SearchArea>
      </div>
      <div className="mt-1">
        <ManageButtonArea
          listOpt={listOpt}
          list={list}
          get_folder_list_by_ajax={get_folder_list_by_ajax}
          select_par_folder_arr={select_par_folder_arr}
          open_folder_manage_popup={open_folder_manage_popup}
          go_delete_file_and_folder_by_ajax={go_delete_file_and_folder_by_ajax}
          go_download_file={go_download_file}
        ></ManageButtonArea>
      </div>
      <div className="mt-1">
        <FileBoxListArea
          infoArr={infoArr}
          folder_list={folder_list}
          select_folder_seq={select_folder_seq}
          set_select_folder_seq={set_select_folder_seq}
          select_par_folder_arr={select_par_folder_arr}
          set_select_par_folder_arr={set_select_par_folder_arr}
          check_folder_seq_arr={check_folder_seq_arr}
          set_check_folder_seq_arr={set_check_folder_seq_arr}
          check_file_seq_arr={check_file_seq_arr}
          set_check_file_seq_arr={set_check_file_seq_arr}
          open_view_file_slide_popup={open_view_file_slide_popup}
          go_in_folder={go_in_folder}
          go_out_folder={go_out_folder}
          go_back_folder={go_back_folder}
        ></FileBoxListArea>
        <Paging now_page={listOpt.now_page}
          num_per_page={listOpt.num_per_page}
          total_rec={countInfo.tot}
          onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
          onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
      </div>
      <DownLoadFileOne ref={DownLoadFileOneRef}></DownLoadFileOne>
      <DownLoadFileAndFolder ref={DownLoadFileAndFolderRef}></DownLoadFileAndFolder>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "folder_manage" &&
            <FolderManagePopup
              listOpt={{
                s_public_sort: listOpt.s_public_sort
              }}
              refresh_data={(inData: any) => {
                refresh_data_by_ajax();
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FolderManagePopup>
          }
          {popupData.sort === "view_file_slide" &&
            <ViewFileSlidePopup
              file_info_arr={popupData.file_info_arr}
              select_file_idx={popupData.select_file_idx}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewFileSlidePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default DataFileListArea;