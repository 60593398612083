import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import SelectTab from "@/pcomponents/common/crud/write/select_tab";
import { useDispatch, useSelector } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import strFunc from '@/lib/lyg/string';

function SearchTabArea(props: any) {
  let myProps = {
    listOpt:{},
    list:(inData:any)=>{},
    set_select_folder_seq:(inData:any)=>{},
    get_folder_list_by_ajax:(inData:any)=>{},
    refresh_data:()=>{},
    ...props
  };
  const user = useSelector((state: any) => state.user);
  const [group_manager_arr, set_group_manager_arr] = useState([]);
  const [is_super, set_is_super] = useState(false);

  useEffect(() => {
    get_group_manager_by_ajax();
  }, [user]);

  const get_group_manager_by_ajax = () => {
    if (strFunc.is_empty(user.main_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(user.comp_group_info["a_seq"])) {
      return false;
    }
    let form_json_data = {
      "s_user_seq": user.main_user_seq,
      "s_group_seq":user.comp_group_info["a_seq"],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/group_manager/list', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_group_manager_arr(response.data["data"]["info_arr"]);
          if(response.data["data"]["info_arr"].length>0){
            set_is_super(true);
          }
        } else {

        }
      });
  };

  const get_filtered_sort_option_arr=()=>{
    let init_public_sort_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'group_board_data', 'key': 'a_public_sort' });
    let option_arr=[];
    let except_val_arr:any=[];
    if(is_super){

    }else if(user.user_grade=="master"){
      except_val_arr=["super"];
    }else{
      except_val_arr=["super","master"];
    }
    for(let i=0;i<init_public_sort_option_arr.length;i++){
      if(except_val_arr.indexOf(init_public_sort_option_arr[i].value)==-1){
        option_arr.push(init_public_sort_option_arr[i]);
      }
    }

    return option_arr;
  };
  let public_sort_option_arr= get_filtered_sort_option_arr();

  return (
    <div>
      <SelectTab
        select_arr={public_sort_option_arr}
        value={myProps.listOpt['s_public_sort']}
        on_select={(value: string) => {
          myProps.listOpt['s_public_sort'] = value;
          myProps.list({
            "now_page": 1,
            "s_public_sort": value,
            "s_par_seq":"",
          });
          myProps.set_select_folder_seq("");
          myProps.get_folder_list_by_ajax({ "s_public_sort": value });
          myProps.refresh_data();
        }}
      ></SelectTab>
    </div>
  );
};
export default SearchTabArea;